:root, .light-theme {
  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98.0%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94.0%);
  --blue5: hsl(209, 95.0%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15.0%);
}
.dark-theme {
  --blue1: hsl(212, 35.0%, 9.2%);
  --blue2: hsl(216, 50.0%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98.0%, 95.8%);
}
:root, .light-theme {
  --slate1: hsl(206, 30.0%, 98.8%);
  --slate2: hsl(210, 16.7%, 97.6%);
  --slate3: hsl(209, 13.3%, 95.3%);
  --slate4: hsl(209, 12.2%, 93.2%);
  --slate5: hsl(208, 11.7%, 91.1%);
  --slate6: hsl(208, 11.3%, 88.9%);
  --slate7: hsl(207, 11.1%, 85.9%);
  --slate8: hsl(205, 10.7%, 78.0%);
  --slate9: hsl(206, 6.0%, 56.1%);
  --slate10: hsl(206, 5.8%, 52.3%);
  --slate11: hsl(206, 6.0%, 43.5%);
  --slate12: hsl(206, 24.0%, 9.0%);
}
.dark-theme {
  --slate1: hsl(200, 7.0%, 8.8%);
  --slate2: hsl(195, 7.1%, 11.0%);
  --slate3: hsl(197, 6.8%, 13.6%);
  --slate4: hsl(198, 6.6%, 15.8%);
  --slate5: hsl(199, 6.4%, 17.9%);
  --slate6: hsl(201, 6.2%, 20.5%);
  --slate7: hsl(203, 6.0%, 24.3%);
  --slate8: hsl(207, 5.6%, 31.6%);
  --slate9: hsl(206, 6.0%, 43.9%);
  --slate10: hsl(206, 5.2%, 49.5%);
  --slate11: hsl(206, 6.0%, 63.0%);
  --slate12: hsl(210, 6.0%, 93.0%);
}
:root, .light-theme {
  --grass1: hsl(116, 50.0%, 98.9%);
  --grass2: hsl(120, 60.0%, 97.1%);
  --grass3: hsl(120, 53.6%, 94.8%);
  --grass4: hsl(121, 47.5%, 91.4%);
  --grass5: hsl(122, 42.6%, 86.5%);
  --grass6: hsl(124, 39.0%, 79.7%);
  --grass7: hsl(126, 37.1%, 70.2%);
  --grass8: hsl(131, 38.1%, 56.3%);
  --grass9: hsl(131, 41.0%, 46.5%);
  --grass10: hsl(132, 43.1%, 42.2%);
  --grass11: hsl(133, 50.0%, 32.5%);
  --grass12: hsl(130, 30.0%, 14.9%);
}
:root, .light-theme {
  --cyan1: hsl(185, 60.0%, 98.7%);
  --cyan2: hsl(185, 73.3%, 97.1%);
  --cyan3: hsl(186, 70.2%, 94.4%);
  --cyan4: hsl(186, 63.8%, 90.6%);
  --cyan5: hsl(187, 58.3%, 85.4%);
  --cyan6: hsl(188, 54.6%, 78.4%);
  --cyan7: hsl(189, 53.7%, 68.7%);
  --cyan8: hsl(189, 60.3%, 52.5%);
  --cyan9: hsl(190, 95.0%, 39.0%);
  --cyan10: hsl(191, 91.2%, 36.8%);
  --cyan11: hsl(192, 85.0%, 31.0%);
  --cyan12: hsl(192, 88.0%, 12.5%);
}
:root, .light-theme {
  --amber1: hsl(39, 70.0%, 99.0%);
  --amber2: hsl(40, 100%, 96.5%);
  --amber3: hsl(44, 100%, 91.7%);
  --amber4: hsl(43, 100%, 86.8%);
  --amber5: hsl(42, 100%, 81.8%);
  --amber6: hsl(38, 99.7%, 76.3%);
  --amber7: hsl(36, 86.1%, 67.1%);
  --amber8: hsl(35, 85.2%, 55.1%);
  --amber9: hsl(39, 100%, 57.0%);
  --amber10: hsl(35, 100%, 55.5%);
  --amber11: hsl(30, 100%, 34.0%);
  --amber12: hsl(20, 80.0%, 17.0%);
}
:root, .light-theme {
  --red1: hsl(359, 100%, 99.4%);
  --red2: hsl(359, 100%, 98.6%);
  --red3: hsl(360, 100%, 96.8%);
  --red4: hsl(360, 97.9%, 94.8%);
  --red5: hsl(360, 90.2%, 91.9%);
  --red6: hsl(360, 81.7%, 87.8%);
  --red7: hsl(359, 74.2%, 81.7%);
  --red8: hsl(359, 69.5%, 74.3%);
  --red9: hsl(358, 75.0%, 59.0%);
  --red10: hsl(358, 69.4%, 55.2%);
  --red11: hsl(358, 65.0%, 48.7%);
  --red12: hsl(354, 50.0%, 14.6%);
}
/** Code mirror */
.mdxeditor .cm-editor {
    --sp-font-mono: var(--font-mono);
    --sp-font-body: var(--font-body);
    padding: var(--sp-space-4) 0;
  }
.mdxeditor .cm-scroller {
    padding: 0 !important;
  }
.mdxeditor .cm-focused {
    outline: none;
  }
.mdxeditor .sp-wrapper {
    border: 1px solid var(--baseLine);
    border-radius: var(--radius-medium);
    overflow: hidden;
  }
.mdxeditor .sp-layout {
    border: none;
  }
.mdxeditor .sp-cm pre {
      white-space: break-spaces;
      word-break: break-word;
      overflow-wrap: anywhere;
      flex-shrink: 1;
    }
/** Diff viewer */
.mdxeditor .cm-mergeView .cm-scroller {
    font-family: var(--font-mono);
    line-height: 1.3rem;
    font-size: var(--text-xs);
  }
/** Diff viewer */
.mdxeditor .cm-sourceView .cm-scroller {
    font-family: var(--font-mono);
    line-height: 1.3rem;
    font-size: var(--text-xs);
  }
.mdxeditor .cm-gutters {
    background: transparent;
    font-size: var(--text-xxs);
  }
.mdxeditor .cm-activeLine {
    background: transparent;
  }
._editorRoot_1qeio_37 {
  --accentBase: var(--blue1);
  --accentBgSubtle: var(--blue2);
  --accentBg: var(--blue3);
  --accentBgHover: var(--blue4);
  --accentBgActive: var(--blue5);
  --accentLine: var(--blue6);
  --accentBorder: var(--blue7);
  --accentBorderHover: var(--blue8);
  --accentSolid: var(--blue9);
  --accentSolidHover: var(--blue10);
  --accentText: var(--blue11);
  --accentTextContrast: var(--blue12);

  --basePageBg: white;
  --baseBase: var(--slate1);
  --baseBgSubtle: var(--slate2);
  --baseBg: var(--slate3);
  --baseBgHover: var(--slate4);
  --baseBgActive: var(--slate5);
  --baseLine: var(--slate6);
  --baseBorder: var(--slate7);
  --baseBorderHover: var(--slate8);
  --baseSolid: var(--slate9);
  --baseSolidHover: var(--slate10);
  --baseText: var(--slate11);
  --baseTextContrast: var(--slate12);

  --admonitionTipBg: var(--cyan4);
  --admonitionTipBorder: var(--cyan8);
  
  --admonitionInfoBg: var(--grass4);
  --admonitionInfoBorder: var(--grass8);

  --admonitionCautionBg: var(--amber4);
  --admonitionCautionBorder: var(--amber8);

  --admonitionDangerBg: var(--red4);
  --admonitionDangerBorder: var(--red8);

  --admonitionNoteBg: var(--slate4);
  --admonitionNoteBorder: var(--slate8);

  --error-color: var(--red10);

  --spacing-0:0px;
  --spacing-px:1px;
  --spacing-0_5:0.125rem;
  --spacing-1:0.25rem;
  --spacing-1_5:0.375rem;
  --spacing-2:0.5rem;
  --spacing-2_5:0.625rem;
  --spacing-3:0.75rem;
  --spacing-3_5:0.875rem;
  --spacing-4:1rem;
  --spacing-5:1.25rem;
  --spacing-6:1.5rem;
  --spacing-7:1.75rem;
  --spacing-8:2rem;
  --spacing-9:2.25rem;
  --spacing-10:2.5rem;
  --spacing-11:2.75rem;
  --spacing-12:3rem;
  --spacing-14:3.5rem;
  --spacing-16:4rem;
  --spacing-20:5rem;
  --spacing-24:6rem;
  --spacing-28:7rem;
  --spacing-32:8rem;
  --spacing-36:9rem;
  --spacing-40:10rem;
  --spacing-44:11rem;
  --spacing-48:12rem;
  --spacing-52:13rem;
  --spacing-56:14rem;
  --spacing-60:15rem;
  --spacing-64:16rem;
  --spacing-72:18rem;
  --spacing-80:20rem;
  --spacing-96:24rem;

  --radius-none:0px;
  --radius-small: var(--spacing-0_5);
  --radius-base: var(--spacing-1);
  --radius-medium: var(--spacing-1_5);
  --radius-large: var(--spacing-2);
  --radius-extra-large: var(--spacing-3);
  --radius-full: 9999px;

  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-body: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  --text-base: 1rem;
  --text-sm: 0.875rem;
  --text-xs: 0.75rem;
  --text-xxs: 0.6rem;

  font-family: var(--font-body);
  color: var(--baseText);
}

._editorWrapper_1qeio_138 {
}

._nestedListItem_1qeio_141 {
  list-style: none;
}

._toolbarRoot_1qeio_145 {
  /* border: var(--spacing-px) solid var(--baseBorder); */
  z-index: 1;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
  border-radius: var(--radius-medium);
  padding: var(--spacing-1_5);
  align-items: center;
  overflow-x: auto;
  position: sticky;
  top: 0;
  background-color: var(--baseBg);
  width: inherit;
}

._toolbarRoot_1qeio_145 div[role=separator] {
    margin: var(--spacing-2) var(--spacing-1);
    border-left: 1px solid var(--baseBorder);
    border-right: 1px solid var(--baseBase);
    height: var(--spacing-4);
  }

._readOnlyToolbarRoot_1qeio_168 {
  pointer-events: none;
  background: var(--baseBase);
}

._readOnlyToolbarRoot_1qeio_168 > div {
    opacity: 0.5;
  }

._toolbarModeSwitch_1qeio_176 {
  opacity: 1 !important;
  margin-left: auto;
  align-self: stretch;
  align-items: stretch;
  display: flex;
  border: 1px solid var(--baseBg);
  border-radius: var(--radius-medium);
  font-size: var(--text-xs);
}

._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186 {
    padding-inline-end: var(--spacing-4);
    padding-inline-start: var(--spacing-4);
  }

._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186:active, ._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186[data-state=on] {
      background-color:var(--baseBorder);
    }

._toolbarGroupOfGroups_1qeio_196 {
  display: flex;
  margin: 0 var(--spacing-1);
}

._toolbarToggleSingleGroup_1qeio_201:first-of-type ._toolbarToggleItem_1qeio_186:only-child, ._toolbarToggleSingleGroup_1qeio_201:only-child ._toolbarToggleItem_1qeio_186:first-child, ._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}

._toolbarToggleSingleGroup_1qeio_201:last-of-type ._toolbarToggleItem_1qeio_186:only-child, ._toolbarToggleSingleGroup_1qeio_201:only-child ._toolbarToggleItem_1qeio_186:last-child, ._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarToggleItem_1qeio_186, ._toolbarButton_1qeio_215 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
}

._toolbarToggleItem_1qeio_186 svg, ._toolbarButton_1qeio_215 svg {
    display: block;
  }

._toolbarToggleItem_1qeio_186:hover, ._toolbarButton_1qeio_215:hover {
    background-color: var(--baseBgActive);
  }

._toolbarToggleItem_1qeio_186:active svg, ._toolbarButton_1qeio_215:active svg {
    transform: translate(1px, 1px);
  }

._toolbarToggleItem_1qeio_186[data-state=on], ._toolbarButton_1qeio_215[data-state=on], ._toolbarToggleItem_1qeio_186:active, ._toolbarButton_1qeio_215:active {
    color: var(--baseTextContrast);
    background-color: var(--baseBgActive);
  }

._toolbarToggleItem_1qeio_186[data-disabled], ._toolbarButton_1qeio_215[data-disabled] {
    pointer-events: none;
    color: var(--baseBorderHover);
  }

._toolbarButton_1qeio_215 {
  border-radius: var(--radius-base);
}

._activeToolbarButton_1qeio_246 {
  color: var(--accentText);
}

._toolbarToggleSingleGroup_1qeio_201 {
  white-space: nowrap;
}

._toolbarNodeKindSelectContainer_1qeio_254, ._toolbarButtonDropdownContainer_1qeio_255, ._toolbarCodeBlockLanguageSelectContent_1qeio_256, ._selectContainer_1qeio_257
{
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  z-index: 3;
  width: var(--spacing-36);
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
  background-color: var(--basePageBg);
  font-size: var(--text-sm);
}

._toolbarButtonDropdownContainer_1qeio_255 {
  border-top-right-radius: var(--radius-base);
}

._toolbarButtonDropdownContainer_1qeio_255 ._selectItem_1qeio_271:first-child {
    border-top-right-radius: var(--radius-base);
  }

._toolbarNodeKindSelectTrigger_1qeio_276, ._toolbarButtonSelectTrigger_1qeio_277, ._selectTrigger_1qeio_278 {
  border: 0;
  background-color: transparent;
  display: flex;
  color: inherit;
  align-items: center;
  width: var(--spacing-36);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-medium);
  white-space: nowrap;
  flex-wrap: nowrap;
  font-size: var(--text-sm);
  background-color: var(--basePageBg);
  margin: 0 var(--spacing-1);
}

._toolbarNodeKindSelectTrigger_1qeio_276[data-state=open], ._toolbarButtonSelectTrigger_1qeio_277[data-state=open], ._selectTrigger_1qeio_278[data-state=open] {
    filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
    border-bottom-right-radius: var(--radius-none);
    border-bottom-left-radius: var(--radius-none);
  }

._selectTrigger_1qeio_278[data-placeholder] > span:first-child {
  color: var(--baseBorderHover);
}

/** used in the sandpack */
._toolbarButtonSelectTrigger_1qeio_277 {
  width: auto;
  padding-inline-start: var(--spacing-2) ;
  padding-inline-end: var(--spacing-1);
  padding-block: var(--spacing-0_5);
}

._toolbarCodeBlockLanguageSelectTrigger_1qeio_312, ._toolbarCodeBlockLanguageSelectContent_1qeio_256 
{
  width: var(--spacing-48);
}

._toolbarNodeKindSelectItem_1qeio_318, ._selectItem_1qeio_271
{
  cursor: default;
  display: flex;
  padding: var(--spacing-2);
}

._toolbarNodeKindSelectItem_1qeio_318[data-highlighted], ._selectItem_1qeio_271[data-highlighted] {
    background-color: var(--baseBg);
  }

._toolbarNodeKindSelectItem_1qeio_318[data-state=checked], ._selectItem_1qeio_271[data-state=checked] {
    color: var(--baseTextContrast);
    background-color: var(--baseBg);
  }

._toolbarNodeKindSelectItem_1qeio_318[data-highlighted], ._selectItem_1qeio_271[data-highlighted] {
    outline: none;
  }

._toolbarNodeKindSelectItem_1qeio_318:last-child, ._selectItem_1qeio_271:last-child {
    border-bottom-left-radius: var(--radius-base);
    border-bottom-right-radius: var(--radius-base);
  }

._toolbarNodeKindSelectDropdownArrow_1qeio_344, ._selectDropdownArrow_1qeio_345 {
  margin-left: auto;
  display: flex;
  align-items: center;
}

._contentEditable_1qeio_351 {
  box-sizing: border-box;
  width: 100%;
  color: var(--baseTextContrast);
  padding: var(--spacing-3);
}

._contentEditable_1qeio_351:focus {
    outline: none;
  }

._sandpackWrapper_1qeio_361 {
  margin-bottom:var(--spacing-5);
}

._frontmatterWrapper_1qeio_365 {
  border-radius: var(--radius-medium);
  padding: var(--spacing-3);
  background-color: var(--baseBgSubtle);
}

._frontmatterWrapper_1qeio_365[data-expanded=true] {
    margin-bottom: var(--spacing-10);
  }

._frontmatterToggleButton_1qeio_375 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  align-items: center;
  gap:var(--spacing-1);
  font-size: var(--text-sm);
}

._propertyPanelTitle_1qeio_383 {
  font-size: var(--text-xs);
  font-weight: 400;
  margin: 0;
  padding-top: var(--spacing-2);
  padding-left: var(--spacing-2);
}

._propertyEditorTable_1qeio_391 {
  table-layout: fixed;
  border-spacing: var(--spacing-2);
}

._propertyEditorTable_1qeio_391 th {
    text-align: left;
    font-size: var(--text-sm);
    padding: var(--spacing-2) var(--spacing-3);
  }

._propertyEditorTable_1qeio_391 col:nth-child(1) {
    width: 30%;
  }

._propertyEditorTable_1qeio_391 col:nth-child(2) {
    width: 70%;
  }

._propertyEditorTable_1qeio_391 td:last-child ._iconButton_1qeio_408 {
    margin-left: var(--spacing-4);
    margin-right: var(--spacing-4);
  }

._propertyEditorTable_1qeio_391 ._readOnlyColumnCell_1qeio_413 {
    padding-left: 0;
  }

._propertyEditorLabelCell_1qeio_418 {
  font-weight: 400;
}

._readOnlyColumnCell_1qeio_413 {
  padding-left: 0;
}

._buttonsFooter_1qeio_426 {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-2);
}

._propertyEditorInput_1qeio_432 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  font-size: var(--text-sm);
}

._iconButton_1qeio_408 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  color:var(--baseText);
}

._iconButton_1qeio_408:hover {
    color: var(--accentText);
  }

._iconButton_1qeio_408:disabled, ._iconButton_1qeio_408:disabled:hover {
    color:var(--baseLine);
  }

._primaryButton_1qeio_453, ._secondaryButton_1qeio_453 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid var(--accentBorder);
  background-color: var(--accentSolidHover);
  color:var(--baseBase);
  font-size: var(--text-xs);
  border-radius: var(--radius-medium);
}

._primaryButton_1qeio_453:disabled, ._secondaryButton_1qeio_453:disabled {
    background: var(--accentLine);
    border-color: var(--accentBg);
  }

._smallButton_1qeio_467 {
  font-size: var(--text-xs);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-base);
}

._secondaryButton_1qeio_453 {
  border: 1px solid var(--baseBorder);
  background-color: var(--baseSolidHover);
  color:var(--baseBase);
}

._dialogForm_1qeio_479 {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
}

._linkDialogEditForm_1qeio_485 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-2);
  padding: 0;
}

._linkDialogInputContainer_1qeio_493 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

._linkDialogInputWrapper_1qeio_499 {
  display: flex;
  align-items: center;
  background-color: var(--baseBase);

  border-radius: var(--radius-base);
  border:1px solid var(--baseBorder);
}

._linkDialogInputWrapper_1qeio_499[data-visible-dropdown=true] {
    border-bottom-left-radius: var(--radius-none) ;
    border-bottom-right-radius: var(--radius-none) ;
    border-bottom-width: 0;
  }

._linkDialogInputWrapper_1qeio_499 > button {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding-right: var(--spacing-2);
  }

._linkDialogInput_1qeio_493, ._dialogInput_1qeio_519 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
}

._linkDialogInput_1qeio_493::-moz-placeholder, ._dialogInput_1qeio_519::-moz-placeholder {
    color: var(--baseBorder);
  }

._linkDialogInput_1qeio_493::placeholder, ._dialogInput_1qeio_519::placeholder {
    color: var(--baseBorder);
  }

._linkDialogAnchor_1qeio_529 {
  position: fixed;
  background-color: highlight;
  z-index: -1;
}

._linkDialogAnchor_1qeio_529[data-visible=true] {
    visibility: visible;
  }

._linkDialogAnchor_1qeio_529[data-visible=false] {
    visibility: hidden;
  }

._linkDialogPopoverContent_1qeio_543, ._tableColumnEditorPopoverContent_1qeio_543, ._dialogContent_1qeio_543 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius:var(--radius-medium);
  border: 1px solid var(--baseBg);
  background-color: var(--basePageBg);
  padding:var(--spacing-1) var(--spacing-2);
  font-size: var(--text-sm);
}

._largeDialogContent_1qeio_555 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  gap: var(--spacing-0_5);
  border-radius:var(--radius-medium);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBgSubtle);
  padding:var(--spacing-4);
  font-size: var(--text-sm);
}

._dialogTitle_1qeio_565 {
  font-size: var(--text-base);
  font-weight: 600;
  padding-left: var(--spacing-2);
}

._dialogCloseButton_1qeio_571 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  position: absolute;
  top: 10px;
  right: 10px;
}

._popoverContent_1qeio_578 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius:var(--radius-medium);
  background-color: var(--baseBgSubtle);
  padding:var(--spacing-2) var(--spacing-2);
  font-size: var(--text-sm);
  z-index: 1;
}

._popoverArrow_1qeio_590 {
  fill: var(--basePageBg);
}

._linkDialogPreviewAnchor_1qeio_594 {
  margin-right: var(--spacing-3);
  display: flex;
  align-items: flex-start;
  color: var(--accentText);
  text-decoration: none;
  border: 1px solid transparent;
}

._linkDialogPreviewAnchor_1qeio_594 svg {
    width: var(--spacing-5);
    height: var(--spacing-5);
    margin-left: var(--spacing-2);
  }

._linkDialogPreviewAnchor_1qeio_594 span {
    max-width: 14rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
._tooltipTrigger_1qeio_615 {
  align-self: center;
}

._tooltipContent_1qeio_619 {
  z-index: 2;
  position: relative;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1) var(--spacing-2);
  font-size: var(--text-xs);
  background-color: var(--baseText);
  color: var(--baseBase);
}

._tooltipContent_1qeio_619 svg {
    fill: var(--baseText);
  }

._actionButton_1qeio_633 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  padding: var(--spacing-1) var(--spacing-1);
  border-radius: var(--radius-medium);
}

._actionButton_1qeio_633 svg {
    display: block;
  }

._actionButton_1qeio_633:hover {
    background-color: var(--baseBgHover);
  }

._actionButton_1qeio_633:active svg {
    transform: translate(1px, 1px);
  }

._actionButton_1qeio_633[data-state=on], ._actionButton_1qeio_633:active {
    background-color: var(--baseBgHover);
    color: var(--baseTextContrast);
  }

._primaryActionButton_1qeio_639 {
  background-color: var(--accentSolid);
  color:var(--baseBase);
}

._primaryActionButton_1qeio_639:hover {
    background-color: var(--accentSolidHover);
    color:var(--baseBase);
  }

._tableEditor_1qeio_648 {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

._tableEditor_1qeio_648 thead > tr > th {
    text-align: right;
  }

._tableEditor_1qeio_648 > tbody > tr > td:not(._toolCell_1qeio_659) {
    border: 1px solid var(--baseBgActive);
    padding: var(--spacing-1) var(--spacing-2);
    white-space: normal;
  }

._tableEditor_1qeio_648 > tbody > tr > td:not(._toolCell_1qeio_659) > div {
      outline: none;
    }

._tableEditor_1qeio_648 > tbody > tr > td:not(._toolCell_1qeio_659) > div > p {
        margin: 0;
      }

._tableEditor_1qeio_648 > tbody > tr > td[data-active=true]:not(._toolCell_1qeio_659) {
      outline: solid 1px var(--baseSolid);
    }

._tableEditor_1qeio_648 ._tableColumnEditorTrigger_1qeio_676, ._tableEditor_1qeio_648 ._tableRowEditorTrigger_1qeio_676, ._tableEditor_1qeio_648 ._addRowButton_1qeio_676, ._tableEditor_1qeio_648 ._addColumnButton_1qeio_676, ._tableEditor_1qeio_648 ._iconButton_1qeio_408 {
    opacity: .15;
  }

._tableEditor_1qeio_648:hover ._tableColumnEditorTrigger_1qeio_676, ._tableEditor_1qeio_648:hover ._tableRowEditorTrigger_1qeio_676, ._tableEditor_1qeio_648:hover ._addRowButton_1qeio_676, ._tableEditor_1qeio_648:hover ._addColumnButton_1qeio_676, ._tableEditor_1qeio_648:hover ._iconButton_1qeio_408 {
      opacity: 0.3;
    }

._tableEditor_1qeio_648:hover ._tableColumnEditorTrigger_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._tableRowEditorTrigger_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._addRowButton_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._addColumnButton_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._iconButton_1qeio_408:hover {
        opacity: 1;
      }

._toolCell_1qeio_659 {
  text-align: right;
}

._toolCell_1qeio_659 button {
    margin: auto;
    display: block;
  }

._tableColumnEditorTrigger_1qeio_676 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  padding: var(--spacing-1);
  border-radius: var(--radius-full);
  opacity: 0.2;
}

._tableColumnEditorTrigger_1qeio_676 svg {
    display: block;
  }

._tableColumnEditorTrigger_1qeio_676:hover {
    background-color: var(--baseBgHover);
  }

._tableColumnEditorTrigger_1qeio_676:active svg {
    transform: translate(1px, 1px);
  }

._tableColumnEditorTrigger_1qeio_676[data-state=on], ._tableColumnEditorTrigger_1qeio_676:active {
    background-color: var(--baseBgHover);
    color: var(--baseTextContrast);
  }

._tableColumnEditorTrigger_1qeio_676[data-active=true] {
    opacity: 1 !important;
  }

._tableColumnEditorToolbar_1qeio_710 {
  display: flex;
}

._tableColumnEditorToolbar_1qeio_710 > button {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-1_5);
  }

._tableColumnEditorToolbar_1qeio_710 > button svg {
    display: block;
  }

._tableColumnEditorToolbar_1qeio_710 > button:hover {
    background-color: var(--baseBgHover);
  }

._tableColumnEditorToolbar_1qeio_710 > button:active svg {
    transform: translate(1px, 1px);
  }

._tableColumnEditorToolbar_1qeio_710 > button[data-state=on], ._tableColumnEditorToolbar_1qeio_710 > button:active {
    background-color: var(--baseBgHover);
    color: var(--baseTextContrast);
  }

._tableColumnEditorToolbar_1qeio_710 [role=separator] {
    margin-left: var(--spacing-1);
    margin-right: var(--spacing-1);
  }


._toggleGroupRoot_1qeio_723 {
  display: inline-flex;
}


._toggleGroupRoot_1qeio_723 button {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding: var(--spacing-1_5);
  }


._toggleGroupRoot_1qeio_723 button svg {
    display: block;
  }


._toggleGroupRoot_1qeio_723 button:hover {
    background-color: var(--baseBgHover);
  }


._toggleGroupRoot_1qeio_723 button:active svg {
    transform: translate(1px, 1px);
  }


._toggleGroupRoot_1qeio_723 button[data-state=on], ._toggleGroupRoot_1qeio_723 button:active {
    background-color: var(--baseBgHover);
    color: var(--baseTextContrast);
  }


._toggleGroupRoot_1qeio_723 button:first-child {
      border-top-left-radius: var(--radius-base);
      border-bottom-left-radius: var(--radius-base);
    }


._toggleGroupRoot_1qeio_723 button:last-child {
      border-top-right-radius: var(--radius-base);
      border-bottom-right-radius: var(--radius-base);
    }

._tableToolsColumn_1qeio_740 {
  width: 3rem;
}

._tableToolsColumn_1qeio_740 button {
    margin: auto;
    display: block;
  }

._leftAlignedCell_1qeio_748 {
  text-align: left;
}

._rightAlignedCell_1qeio_752 {
  text-align: right;
}

._centeredCell_1qeio_756 {
  text-align: center;
}

._addColumnButton_1qeio_676, ._addRowButton_1qeio_676 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  background-color: var(--baseBase);

  display: flex;
  align-items: center;
}

._addColumnButton_1qeio_676 svg, ._addRowButton_1qeio_676 svg {
    display: block;
  }

._addColumnButton_1qeio_676:hover, ._addRowButton_1qeio_676:hover {
    background-color: var(--baseBgHover);
  }

._addColumnButton_1qeio_676:active svg, ._addRowButton_1qeio_676:active svg {
    transform: translate(1px, 1px);
  }

._addColumnButton_1qeio_676[data-state=on], ._addRowButton_1qeio_676[data-state=on], ._addColumnButton_1qeio_676:active, ._addRowButton_1qeio_676:active {
    background-color: var(--baseBgHover);
    color: var(--baseTextContrast);
  }

._addColumnButton_1qeio_676 svg, ._addRowButton_1qeio_676 svg {
    margin: auto;
  }

._addRowButton_1qeio_676 {
  width: 100%;
  margin-top: var(--spacing-px);
  box-sizing: border-box;
  border-bottom-right-radius: var(--radius-medium);
  border-bottom-left-radius: var(--radius-medium);
}

._addColumnButton_1qeio_676 {
  margin-left: var(--spacing-px);
  height: 100%;
  border-top-right-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}

/** Dialog */
._dialogOverlay_1qeio_787 {
  position: fixed;
  inset: 0;
  animation: _overlayShow_1qeio_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: var(--baseBase);
  z-index: 51;
  opacity: 0.5;
}

._dialogContent_1qeio_543, ._largeDialogContent_1qeio_555 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: _contentShow_1qeio_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 52;
}

._dialogContent_1qeio_543:focus, ._largeDialogContent_1qeio_555:focus {
  outline: none;
}


@keyframes _overlayShow_1qeio_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: .5;
  }
}

@keyframes _contentShow_1qeio_1 {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

._focusedImage_1qeio_830 {
  outline: highlight solid 2px;
}

._imageWrapper_1qeio_834 {
  display: inline-block;
  position: relative;
}

._imageWrapper_1qeio_834[draggable=true] {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

._editImageButton_1qeio_846 {
  position: absolute;
  right: var(--spacing-2);
  top: var(--spacing-2);
  background: var(--baseBase);
  border-radius: var(--radius-full);
}

._editImageButton_1qeio_846 svg {
    display: block;
  }

._inlineEditor_1qeio_858 {
  display: inline-flex;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  align-items: center;
  background: var(--baseBg);
}

._blockEditor_1qeio_867 {
  display: flex;
  justify-content: stretch;
  border-radius: var(--radius-medium);
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  align-items: center;
  background: var(--baseBg);
}

._blockEditor_1qeio_867 ._nestedEditor_1qeio_876 {
    flex-grow: 1;
  }

._nestedEditor_1qeio_876 {
  background: white;
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-medium);
}

._nestedEditor_1qeio_876 > p {
    margin: 0;
  }

._nestedEditor_1qeio_876:focus {
    outline: none;
  }

._genericComponentName_1qeio_893 {
  font-size: var(--text-sm);
  color: var(--baseText);
  padding-right: var(--spacing-2);
}

._diffSourceToggle_1qeio_899 {
  border-radius: var(--radius-medium);
  display: flex;
}

._diffSourceToggle_1qeio_899 ._toolbarToggleItem_1qeio_186 {
    padding: 0;
  }

._diffSourceToggle_1qeio_899 ._toolbarToggleItem_1qeio_186 > span {
      display: block;
      padding: var(--spacing-1) var(--spacing-2);
    }

._selectWithLabel_1qeio_912 {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  margin-left: var(--spacing-2);
}

._selectWithLabel_1qeio_912 > label {
    font-size: var(--text-sm);
  }

._selectWithLabel_1qeio_912 ._selectTrigger_1qeio_278 {
    border: 1px solid var(--baseBorder);
  }

._toolbarTitleMode_1qeio_926 {
  font-size: var(--text-sm);
  margin-left: var(--spacing-2);
}


._imageControlWrapperResizing_1qeio_932 {
  touch-action: none;
}

._imageResizer_1qeio_936 {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: var(--accentText);
  border: 1px solid var(--baseBg);
}

._imageResizer_1qeio_936._imageResizerN_1qeio_945 {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

._imageResizer_1qeio_936._imageResizerNe_1qeio_951 {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

._imageResizer_1qeio_936._imageResizerE_1qeio_957 {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

._imageResizer_1qeio_936._imageResizerSe_1qeio_963 {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

._imageResizer_1qeio_936._imageResizerS_1qeio_963 {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

._imageResizer_1qeio_936._imageResizerSw_1qeio_975 {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

._imageResizer_1qeio_936._imageResizerW_1qeio_981 {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

._imageResizer_1qeio_936._imageResizerNw_1qeio_987 {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

._placeholder_1qeio_993 {
    color: var(--baseSolid);
    overflow: hidden;
    position: absolute;
    top: 0;
    padding: var(--spacing-3);
    text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    white-space: nowrap;
    display: inline-block;
    pointer-events: none;
}
  
._rootContentEditableWrapper_1qeio_1006 {
  position: relative;
}



._downshiftContainer_1qeio_1012 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

._downshiftInputWrapper_1qeio_1018 {
  display: flex;
  align-items: center;
  background-color: var(--baseBase);

  border-radius: var(--radius-base);
  border:1px solid var(--baseBorder);
}

._downshiftInputWrapper_1qeio_1018[data-visible-dropdown=true] {
    border-bottom-left-radius: var(--radius-none) ;
    border-bottom-right-radius: var(--radius-none) ;
    border-bottom-width: 0;
  }

._downshiftInputWrapper_1qeio_1018 > button {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    all: unset;
    box-sizing: border-box;
    cursor: default;
    padding-right: var(--spacing-2);
  }

._downshiftInput_1qeio_1018 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
}

._downshiftInput_1qeio_1018::-moz-placeholder {
    color: var(--baseBorder);
  }

._downshiftInput_1qeio_1018::placeholder {
    color: var(--baseBorder);
  }

._downshiftAutocompleteContainer_1qeio_1048 {
  position: relative;
}

._downshiftAutocompleteContainer_1qeio_1048 ul {
    all: unset;
    box-sizing: border-box;
    position: absolute;
    font-size: var(--text-sm);
    width: 100%;
    display: none;
    border-bottom-left-radius: var(--radius-medium) ;
    border-bottom-right-radius: var(--radius-medium) ;
    max-height: var(--spacing-48);
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid var(--baseBorder);
    border-top-width: 0;
    background-color: var(--baseBase);
  }

._downshiftAutocompleteContainer_1qeio_1048 ul[data-visible=true] {
      display: block;
    }

._downshiftAutocompleteContainer_1qeio_1048 ul li {
      padding: var(--spacing-2) var(--spacing-3);
      white-space: nowrap;
      margin-bottom: var(--spacing-1);
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

._downshiftAutocompleteContainer_1qeio_1048 ul li[data-selected=true] {
        background-color: var(--baseBgSubtle);
      }

._downshiftAutocompleteContainer_1qeio_1048 ul li[data-highlighted=true] {
        background-color: var(--baseBgHover);
      }

._downshiftAutocompleteContainer_1qeio_1048 ul li:last-of-type {
        border-bottom-left-radius: var(--radius-medium) ;
        border-bottom-right-radius: var(--radius-medium) ;
      }

._textInput_1qeio_1093 {
  all: unset;
  border-radius: var(--radius-base);
  border:1px solid var(--baseBorder);
  background-color: var(--baseBase);
  padding: var(--spacing-2) var(--spacing-3);
}

form._multiFieldForm_1qeio_1101 {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-2);
  gap: var(--spacing-2);
}

form._multiFieldForm_1qeio_1101 ._formField_1qeio_1107 {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

form._multiFieldForm_1qeio_1101 ._formField_1qeio_1107 label {
      font-size: var(--text-xs);
    }

._markdownParseError_1qeio_1118 {
  border-radius: var(--radius-base);
  border: 1px solid var(--error-color);
  padding: var(--spacing-2);
  margin-block: var(--spacing-2);
  color: var(--error-color);
  font-size: var(--text-xs);
}


:root, ._light-theme_7hn0e_1 {
  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98.0%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94.0%);
  --blue5: hsl(209, 95.0%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15.0%);
}
._dark-theme_7hn0e_1 {
  --blue1: hsl(212, 35.0%, 9.2%);
  --blue2: hsl(216, 50.0%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50.0%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98.0%, 95.8%);
}
:root, ._light-theme_7hn0e_1 {
  --slate1: hsl(206, 30.0%, 98.8%);
  --slate2: hsl(210, 16.7%, 97.6%);
  --slate3: hsl(209, 13.3%, 95.3%);
  --slate4: hsl(209, 12.2%, 93.2%);
  --slate5: hsl(208, 11.7%, 91.1%);
  --slate6: hsl(208, 11.3%, 88.9%);
  --slate7: hsl(207, 11.1%, 85.9%);
  --slate8: hsl(205, 10.7%, 78.0%);
  --slate9: hsl(206, 6.0%, 56.1%);
  --slate10: hsl(206, 5.8%, 52.3%);
  --slate11: hsl(206, 6.0%, 43.5%);
  --slate12: hsl(206, 24.0%, 9.0%);
}
._dark-theme_7hn0e_1 {
  --slate1: hsl(200, 7.0%, 8.8%);
  --slate2: hsl(195, 7.1%, 11.0%);
  --slate3: hsl(197, 6.8%, 13.6%);
  --slate4: hsl(198, 6.6%, 15.8%);
  --slate5: hsl(199, 6.4%, 17.9%);
  --slate6: hsl(201, 6.2%, 20.5%);
  --slate7: hsl(203, 6.0%, 24.3%);
  --slate8: hsl(207, 5.6%, 31.6%);
  --slate9: hsl(206, 6.0%, 43.9%);
  --slate10: hsl(206, 5.2%, 49.5%);
  --slate11: hsl(206, 6.0%, 63.0%);
  --slate12: hsl(210, 6.0%, 93.0%);
}
:root, ._light-theme_7hn0e_1 {
  --grass1: hsl(116, 50.0%, 98.9%);
  --grass2: hsl(120, 60.0%, 97.1%);
  --grass3: hsl(120, 53.6%, 94.8%);
  --grass4: hsl(121, 47.5%, 91.4%);
  --grass5: hsl(122, 42.6%, 86.5%);
  --grass6: hsl(124, 39.0%, 79.7%);
  --grass7: hsl(126, 37.1%, 70.2%);
  --grass8: hsl(131, 38.1%, 56.3%);
  --grass9: hsl(131, 41.0%, 46.5%);
  --grass10: hsl(132, 43.1%, 42.2%);
  --grass11: hsl(133, 50.0%, 32.5%);
  --grass12: hsl(130, 30.0%, 14.9%);
}
:root, ._light-theme_7hn0e_1 {
  --cyan1: hsl(185, 60.0%, 98.7%);
  --cyan2: hsl(185, 73.3%, 97.1%);
  --cyan3: hsl(186, 70.2%, 94.4%);
  --cyan4: hsl(186, 63.8%, 90.6%);
  --cyan5: hsl(187, 58.3%, 85.4%);
  --cyan6: hsl(188, 54.6%, 78.4%);
  --cyan7: hsl(189, 53.7%, 68.7%);
  --cyan8: hsl(189, 60.3%, 52.5%);
  --cyan9: hsl(190, 95.0%, 39.0%);
  --cyan10: hsl(191, 91.2%, 36.8%);
  --cyan11: hsl(192, 85.0%, 31.0%);
  --cyan12: hsl(192, 88.0%, 12.5%);
}
:root, ._light-theme_7hn0e_1 {
  --amber1: hsl(39, 70.0%, 99.0%);
  --amber2: hsl(40, 100%, 96.5%);
  --amber3: hsl(44, 100%, 91.7%);
  --amber4: hsl(43, 100%, 86.8%);
  --amber5: hsl(42, 100%, 81.8%);
  --amber6: hsl(38, 99.7%, 76.3%);
  --amber7: hsl(36, 86.1%, 67.1%);
  --amber8: hsl(35, 85.2%, 55.1%);
  --amber9: hsl(39, 100%, 57.0%);
  --amber10: hsl(35, 100%, 55.5%);
  --amber11: hsl(30, 100%, 34.0%);
  --amber12: hsl(20, 80.0%, 17.0%);
}
:root, ._light-theme_7hn0e_1 {
  --red1: hsl(359, 100%, 99.4%);
  --red2: hsl(359, 100%, 98.6%);
  --red3: hsl(360, 100%, 96.8%);
  --red4: hsl(360, 97.9%, 94.8%);
  --red5: hsl(360, 90.2%, 91.9%);
  --red6: hsl(360, 81.7%, 87.8%);
  --red7: hsl(359, 74.2%, 81.7%);
  --red8: hsl(359, 69.5%, 74.3%);
  --red9: hsl(358, 75.0%, 59.0%);
  --red10: hsl(358, 69.4%, 55.2%);
  --red11: hsl(358, 65.0%, 48.7%);
  --red12: hsl(354, 50.0%, 14.6%);
}
._bold_7hn0e_10 {
  font-weight: bold;
}
._italic_7hn0e_14 {
  font-style: italic;
}
._underline_7hn0e_18 {
  text-decoration: underline;
}
._bold_7hn0e_10 {
    font-weight: 700
}
._italic_7hn0e_14 {
    font-style: italic
}
._underline_7hn0e_18 {
    text-decoration: underline
}
._strikethrough_7hn0e_34 {
    text-decoration: line-through
}
._underlineStrikethrough_7hn0e_38 {
    text-decoration: underline line-through
}
._subscript_7hn0e_42 {
    font-size: .8em;
    vertical-align: sub!important
}
._superscript_7hn0e_47 {
    font-size: .8em;
    vertical-align: super
}
._code_7hn0e_52 {
    background-color: var(--baseBg);
    padding: 1px .25rem;
    font-family: var(--font-mono);
    font-size: 94%
}
._nestedListItem_7hn0e_59 {
  list-style: none;
  list-style-type: none;
}
._nestedListItem_7hn0e_59:before, ._nestedListItem_7hn0e_59:after {
  display: none;
}
._listitem_7hn0e_69 {
  margin: var(--spacing-2) 0;
}
._listItemChecked_7hn0e_73, ._listItemUnchecked_7hn0e_74 {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  margin-inline-start: -1rem;
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
  list-style-type: none;
  outline: none;
}
._listItemChecked_7hn0e_73 {
  text-decoration: line-through;
}
._listItemUnchecked_7hn0e_74:before, ._listItemChecked_7hn0e_73:before {
  content: '';
  width: var(--spacing-4);
  height: var(--spacing-4);
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
._listItemUnchecked_7hn0e_74[dir='rtl']:before, ._listItemChecked_7hn0e_73[dir='rtl']:before {
  left: auto;
  right: 0;
}
._listItemUnchecked_7hn0e_74:focus:before, ._listItemChecked_7hn0e_73:focus:before {
  box-shadow: 0 0 0 2px var(--accentBgActive);
  border-radius: var(--radius-small);
}
._listItemUnchecked_7hn0e_74:before {
  border: 1px solid var(--baseBorder);
  border-radius: var(--radius-small);
}
._listItemChecked_7hn0e_73:before {
  border: 1px solid var(--accentBorder);
  border-radius: var(--radius-small);
  background-color: var(--accentSolid);
  background-repeat: no-repeat;
}
._listItemChecked_7hn0e_73:after {
  content: '';
  cursor: pointer;
  border-color: var(--baseBase);
  border-style: solid;
  position: absolute;
  display: block;
  top: var(--spacing-0_5);
  width: var(--spacing-1);
  left: var(--spacing-1_5);
  right: var(--spacing-1_5);
  height: var(--spacing-2);
  transform: rotate(45deg);
  border-width: 0 var(--spacing-0_5) var(--spacing-0_5) 0;
}
._nestedListItem_7hn0e_59 {
  list-style-type: none;
}
._nestedListItem_7hn0e_59:before, ._nestedListItem_7hn0e_59:after {
  display: none;
}
._admonitionDanger_7hn0e_151, ._admonitionInfo_7hn0e_151, ._admonitionNote_7hn0e_151, ._admonitionTip_7hn0e_151, ._admonitionCaution_7hn0e_151 {
  padding: var(--spacing-2);
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  border-left: 3px solid var(--admonitionBorder);
  background-color: var(--admonitionBg);
}
._admonitionInfo_7hn0e_151 {
  --admonitionBorder: var(--admonitionInfoBorder);
  --admonitionBg: var(--admonitionInfoBg);
}
._admonitionTip_7hn0e_151 {
  --admonitionBorder: var(--admonitionTipBorder);
  --admonitionBg: var(--admonitionTipBg);
}
._admonitionCaution_7hn0e_151 {
  --admonitionBorder: var(--admonitionCautionBorder);
  --admonitionBg: var(--admonitionCautionBg);
}
._admonitionDanger_7hn0e_151 {
  --admonitionBorder: var(--admonitionDangerBorder);
  --admonitionBg: var(--admonitionDangerBg);
}
._admonitionNote_7hn0e_151 {
  --admonitionBorder: var(--admonitionNoteBorder);
  --admonitionBg: var(--admonitionNoteBg);
}
