:root, .light-theme {
  --blue1: #fbfdff;
  --blue2: #f5faff;
  --blue3: #edf6ff;
  --blue4: #e1f0ff;
  --blue5: #cee7fe;
  --blue6: #b7d9f8;
  --blue7: #96c7f2;
  --blue8: #5eb0ef;
  --blue9: #0090ff;
  --blue10: #0081f1;
  --blue11: #006adc;
  --blue12: #00254d;
}

.dark-theme {
  --blue1: #0f1720;
  --blue2: #0f1b2d;
  --blue3: #10243e;
  --blue4: #102a4c;
  --blue5: #0f3058;
  --blue6: #0d3868;
  --blue7: #0a4481;
  --blue8: #0954a5;
  --blue9: #0090ff;
  --blue10: #369eff;
  --blue11: #52a9ff;
  --blue12: #eaf6ff;
}

:root, .light-theme {
  --slate1: #fbfcfd;
  --slate2: #f8f9fa;
  --slate3: #f1f3f5;
  --slate4: #eceef0;
  --slate5: #e6e8eb;
  --slate6: #dfe3e6;
  --slate7: #d7dbdf;
  --slate8: #c1c8cd;
  --slate9: #889096;
  --slate10: #7e868c;
  --slate11: #687076;
  --slate12: #11181c;
}

.dark-theme {
  --slate1: #151718;
  --slate2: #1a1d1e;
  --slate3: #202425;
  --slate4: #26292b;
  --slate5: #2b2f31;
  --slate6: #313538;
  --slate7: #3a3f42;
  --slate8: #4c5155;
  --slate9: #697177;
  --slate10: #787f85;
  --slate11: #9ba1a6;
  --slate12: #ecedee;
}

:root, .light-theme {
  --grass1: #fbfefb;
  --grass2: #f3fcf3;
  --grass3: #ebf9eb;
  --grass4: #dff3df;
  --grass5: #ceebcf;
  --grass6: #b7dfba;
  --grass7: #97cf9c;
  --grass8: #65ba75;
  --grass9: #46a758;
  --grass10: #3d9a50;
  --grass11: #297c3b;
  --grass12: #1b311e;
  --cyan1: #fafdfe;
  --cyan2: #f2fcfd;
  --cyan3: #e7f9fb;
  --cyan4: #d8f3f6;
  --cyan5: #c4eaef;
  --cyan6: #aadee6;
  --cyan7: #84cdda;
  --cyan8: #3db9cf;
  --cyan9: #05a2c2;
  --cyan10: #0894b3;
  --cyan11: #0c7792;
  --cyan12: #04313c;
  --amber1: #fefdfb;
  --amber2: #fff9ed;
  --amber3: #fff4d5;
  --amber4: #ffecbc;
  --amber5: #ffe3a2;
  --amber6: #ffd386;
  --amber7: #f3ba63;
  --amber8: #ee9d2b;
  --amber9: #ffb224;
  --amber10: #ffa01c;
  --amber11: #ad5700;
  --amber12: #4e2009;
  --red1: #fffcfc;
  --red2: #fff8f8;
  --red3: #ffefef;
  --red4: #ffe5e5;
  --red5: #fdd8d8;
  --red6: #f9c6c6;
  --red7: #f3aeaf;
  --red8: #eb9091;
  --red9: #e5484d;
  --red10: #dc3d43;
  --red11: #cd2b31;
  --red12: #381316;
}

.mdxeditor .cm-editor {
  --sp-font-mono: var(--font-mono);
  --sp-font-body: var(--font-body);
  padding: var(--sp-space-4) 0;
}

.mdxeditor .cm-scroller {
  padding: 0 !important;
}

.mdxeditor .cm-focused {
  outline: none;
}

.mdxeditor .sp-wrapper {
  border: 1px solid var(--baseLine);
  border-radius: var(--radius-medium);
  overflow: hidden;
}

.mdxeditor .sp-layout {
  border: none;
}

.mdxeditor .sp-cm pre {
  white-space: break-spaces;
  word-break: break-word;
  overflow-wrap: anywhere;
  flex-shrink: 1;
}

.mdxeditor .cm-mergeView .cm-scroller, .mdxeditor .cm-sourceView .cm-scroller {
  font-family: var(--font-mono);
  line-height: 1.3rem;
  font-size: var(--text-xs);
}

.mdxeditor .cm-gutters {
  font-size: var(--text-xxs);
  background: none;
}

.mdxeditor .cm-activeLine {
  background: none;
}

._editorRoot_1qeio_37 {
  --accentBase: var(--blue1);
  --accentBgSubtle: var(--blue2);
  --accentBg: var(--blue3);
  --accentBgHover: var(--blue4);
  --accentBgActive: var(--blue5);
  --accentLine: var(--blue6);
  --accentBorder: var(--blue7);
  --accentBorderHover: var(--blue8);
  --accentSolid: var(--blue9);
  --accentSolidHover: var(--blue10);
  --accentText: var(--blue11);
  --accentTextContrast: var(--blue12);
  --basePageBg: white;
  --baseBase: var(--slate1);
  --baseBgSubtle: var(--slate2);
  --baseBg: var(--slate3);
  --baseBgHover: var(--slate4);
  --baseBgActive: var(--slate5);
  --baseLine: var(--slate6);
  --baseBorder: var(--slate7);
  --baseBorderHover: var(--slate8);
  --baseSolid: var(--slate9);
  --baseSolidHover: var(--slate10);
  --baseText: var(--slate11);
  --baseTextContrast: var(--slate12);
  --admonitionTipBg: var(--cyan4);
  --admonitionTipBorder: var(--cyan8);
  --admonitionInfoBg: var(--grass4);
  --admonitionInfoBorder: var(--grass8);
  --admonitionCautionBg: var(--amber4);
  --admonitionCautionBorder: var(--amber8);
  --admonitionDangerBg: var(--red4);
  --admonitionDangerBorder: var(--red8);
  --admonitionNoteBg: var(--slate4);
  --admonitionNoteBorder: var(--slate8);
  --error-color: var(--red10);
  --spacing-0: 0px;
  --spacing-px: 1px;
  --spacing-0_5: .125rem;
  --spacing-1: .25rem;
  --spacing-1_5: .375rem;
  --spacing-2: .5rem;
  --spacing-2_5: .625rem;
  --spacing-3: .75rem;
  --spacing-3_5: .875rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-28: 7rem;
  --spacing-32: 8rem;
  --spacing-36: 9rem;
  --spacing-40: 10rem;
  --spacing-44: 11rem;
  --spacing-48: 12rem;
  --spacing-52: 13rem;
  --spacing-56: 14rem;
  --spacing-60: 15rem;
  --spacing-64: 16rem;
  --spacing-72: 18rem;
  --spacing-80: 20rem;
  --spacing-96: 24rem;
  --radius-none: 0px;
  --radius-small: var(--spacing-0_5);
  --radius-base: var(--spacing-1);
  --radius-medium: var(--spacing-1_5);
  --radius-large: var(--spacing-2);
  --radius-extra-large: var(--spacing-3);
  --radius-full: 9999px;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-body: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --text-base: 1rem;
  --text-sm: .875rem;
  --text-xs: .75rem;
  --text-xxs: .6rem;
  font-family: var(--font-body);
  color: var(--baseText);
}

._nestedListItem_1qeio_141 {
  list-style: none;
}

._toolbarRoot_1qeio_145 {
  z-index: 1;
  gap: var(--spacing-1);
  border-radius: var(--radius-medium);
  padding: var(--spacing-1_5);
  background-color: var(--baseBg);
  width: inherit;
  flex-direction: row;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  overflow-x: auto;
}

._toolbarRoot_1qeio_145 div[role="separator"] {
  margin: var(--spacing-2) var(--spacing-1);
  border-left: 1px solid var(--baseBorder);
  border-right: 1px solid var(--baseBase);
  height: var(--spacing-4);
}

._readOnlyToolbarRoot_1qeio_168 {
  pointer-events: none;
  background: var(--baseBase);
}

._readOnlyToolbarRoot_1qeio_168 > div {
  opacity: .5;
}

._toolbarModeSwitch_1qeio_176 {
  border: 1px solid var(--baseBg);
  border-radius: var(--radius-medium);
  font-size: var(--text-xs);
  align-self: stretch;
  align-items: stretch;
  margin-left: auto;
  display: flex;
  opacity: 1 !important;
}

._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186 {
  padding-inline-start: var(--spacing-4);
  padding-inline-end: var(--spacing-4);
}

._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186:active, ._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186[data-state="on"] {
  background-color: var(--baseBorder);
}

._toolbarGroupOfGroups_1qeio_196 {
  margin: 0 var(--spacing-1);
  display: flex;
}

._toolbarToggleSingleGroup_1qeio_201:first-of-type ._toolbarToggleItem_1qeio_186:only-child, ._toolbarToggleSingleGroup_1qeio_201:only-child ._toolbarToggleItem_1qeio_186:first-child, ._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}

._toolbarToggleSingleGroup_1qeio_201:last-of-type ._toolbarToggleItem_1qeio_186:only-child, ._toolbarToggleSingleGroup_1qeio_201:only-child ._toolbarToggleItem_1qeio_186:last-child, ._toolbarModeSwitch_1qeio_176 ._toolbarToggleItem_1qeio_186:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarToggleItem_1qeio_186, ._toolbarButton_1qeio_215 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
  background-color: #0000;
  border: 0;
}

._toolbarToggleItem_1qeio_186 svg, ._toolbarButton_1qeio_215 svg {
  display: block;
}

._toolbarToggleItem_1qeio_186:hover, ._toolbarButton_1qeio_215:hover {
  background-color: var(--baseBgActive);
}

._toolbarToggleItem_1qeio_186:active svg, ._toolbarButton_1qeio_215:active svg {
  transform: translate(1px, 1px);
}

._toolbarToggleItem_1qeio_186[data-state="on"], ._toolbarButton_1qeio_215[data-state="on"], ._toolbarToggleItem_1qeio_186:active, ._toolbarButton_1qeio_215:active {
  color: var(--baseTextContrast);
  background-color: var(--baseBgActive);
}

._toolbarToggleItem_1qeio_186[data-disabled], ._toolbarButton_1qeio_215[data-disabled] {
  pointer-events: none;
  color: var(--baseBorderHover);
}

._toolbarButton_1qeio_215 {
  border-radius: var(--radius-base);
}

._activeToolbarButton_1qeio_246 {
  color: var(--accentText);
}

._toolbarToggleSingleGroup_1qeio_201 {
  white-space: nowrap;
}

._toolbarNodeKindSelectContainer_1qeio_254, ._toolbarButtonDropdownContainer_1qeio_255, ._toolbarCodeBlockLanguageSelectContent_1qeio_256, ._selectContainer_1qeio_257 {
  filter: drop-shadow(0 2px 2px #0003);
  z-index: 3;
  width: var(--spacing-36);
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
  background-color: var(--basePageBg);
  font-size: var(--text-sm);
}

._toolbarButtonDropdownContainer_1qeio_255, ._toolbarButtonDropdownContainer_1qeio_255 ._selectItem_1qeio_271:first-child {
  border-top-right-radius: var(--radius-base);
}

._toolbarNodeKindSelectTrigger_1qeio_276, ._toolbarButtonSelectTrigger_1qeio_277, ._selectTrigger_1qeio_278 {
  color: inherit;
  width: var(--spacing-36);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-medium);
  white-space: nowrap;
  font-size: var(--text-sm);
  background-color: #0000;
  background-color: var(--basePageBg);
  margin: 0 var(--spacing-1);
  border: 0;
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

._toolbarNodeKindSelectTrigger_1qeio_276[data-state="open"], ._toolbarButtonSelectTrigger_1qeio_277[data-state="open"], ._selectTrigger_1qeio_278[data-state="open"] {
  filter: drop-shadow(0 2px 2px #0003);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-left-radius: var(--radius-none);
}

._selectTrigger_1qeio_278[data-placeholder] > span:first-child {
  color: var(--baseBorderHover);
}

._toolbarButtonSelectTrigger_1qeio_277 {
  width: auto;
  padding-inline-start: var(--spacing-2);
  padding-inline-end: var(--spacing-1);
  padding-block: var(--spacing-0_5);
}

._toolbarCodeBlockLanguageSelectTrigger_1qeio_312, ._toolbarCodeBlockLanguageSelectContent_1qeio_256 {
  width: var(--spacing-48);
}

._toolbarNodeKindSelectItem_1qeio_318, ._selectItem_1qeio_271 {
  cursor: default;
  padding: var(--spacing-2);
  display: flex;
}

._toolbarNodeKindSelectItem_1qeio_318[data-highlighted], ._selectItem_1qeio_271[data-highlighted] {
  background-color: var(--baseBg);
}

._toolbarNodeKindSelectItem_1qeio_318[data-state="checked"], ._selectItem_1qeio_271[data-state="checked"] {
  color: var(--baseTextContrast);
  background-color: var(--baseBg);
}

._toolbarNodeKindSelectItem_1qeio_318[data-highlighted], ._selectItem_1qeio_271[data-highlighted] {
  outline: none;
}

._toolbarNodeKindSelectItem_1qeio_318:last-child, ._selectItem_1qeio_271:last-child {
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarNodeKindSelectDropdownArrow_1qeio_344, ._selectDropdownArrow_1qeio_345 {
  align-items: center;
  margin-left: auto;
  display: flex;
}

._contentEditable_1qeio_351 {
  box-sizing: border-box;
  width: 100%;
  color: var(--baseTextContrast);
  padding: var(--spacing-3);
}

._contentEditable_1qeio_351:focus {
  outline: none;
}

._sandpackWrapper_1qeio_361 {
  margin-bottom: var(--spacing-5);
}

._frontmatterWrapper_1qeio_365 {
  border-radius: var(--radius-medium);
  padding: var(--spacing-3);
  background-color: var(--baseBgSubtle);
}

._frontmatterWrapper_1qeio_365[data-expanded="true"] {
  margin-bottom: var(--spacing-10);
}

._frontmatterToggleButton_1qeio_375 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  align-items: center;
  gap: var(--spacing-1);
  font-size: var(--text-sm);
  background-color: #0000;
  border: 0;
  display: flex;
}

._propertyPanelTitle_1qeio_383 {
  font-size: var(--text-xs);
  padding-top: var(--spacing-2);
  padding-left: var(--spacing-2);
  margin: 0;
  font-weight: 400;
}

._propertyEditorTable_1qeio_391 {
  table-layout: fixed;
  border-spacing: var(--spacing-2);
}

._propertyEditorTable_1qeio_391 th {
  text-align: left;
  font-size: var(--text-sm);
  padding: var(--spacing-2) var(--spacing-3);
}

._propertyEditorTable_1qeio_391 col:nth-child(1) {
  width: 30%;
}

._propertyEditorTable_1qeio_391 col:nth-child(2) {
  width: 70%;
}

._propertyEditorTable_1qeio_391 td:last-child ._iconButton_1qeio_408 {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
}

._propertyEditorTable_1qeio_391 ._readOnlyColumnCell_1qeio_413 {
  padding-left: 0;
}

._propertyEditorLabelCell_1qeio_418 {
  font-weight: 400;
}

._readOnlyColumnCell_1qeio_413 {
  padding-left: 0;
}

._buttonsFooter_1qeio_426 {
  justify-content: flex-end;
  gap: var(--spacing-2);
  display: flex;
}

._propertyEditorInput_1qeio_432 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--radius-base);
  border: 0;
  border: 1px solid var(--baseBorder);
  background-color: #0000;
  background-color: var(--baseBase);
  font-size: var(--text-sm);
}

._iconButton_1qeio_408 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  color: var(--baseText);
  background-color: #0000;
  border: 0;
}

._iconButton_1qeio_408:hover {
  color: var(--accentText);
}

._iconButton_1qeio_408:disabled, ._iconButton_1qeio_408:disabled:hover {
  color: var(--baseLine);
}

._primaryButton_1qeio_453, ._secondaryButton_1qeio_453 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2) var(--spacing-3);
  border: 0;
  border: 1px solid var(--accentBorder);
  background-color: #0000;
  background-color: var(--accentSolidHover);
  color: var(--baseBase);
  font-size: var(--text-xs);
  border-radius: var(--radius-medium);
}

._primaryButton_1qeio_453:disabled, ._secondaryButton_1qeio_453:disabled {
  background: var(--accentLine);
  border-color: var(--accentBg);
}

._smallButton_1qeio_467 {
  font-size: var(--text-xs);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-base);
}

._secondaryButton_1qeio_453 {
  border: 1px solid var(--baseBorder);
  background-color: var(--baseSolidHover);
  color: var(--baseBase);
}

._dialogForm_1qeio_479 {
  gap: var(--spacing-2);
  flex-direction: row;
  display: flex;
}

._linkDialogEditForm_1qeio_485 {
  align-items: stretch;
  gap: var(--spacing-2);
  flex-direction: column;
  padding: 0;
  display: flex;
}

._linkDialogInputContainer_1qeio_493 {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

._linkDialogInputWrapper_1qeio_499 {
  background-color: var(--baseBase);
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  align-items: center;
  display: flex;
}

._linkDialogInputWrapper_1qeio_499[data-visible-dropdown="true"] {
  border-bottom-left-radius: var(--radius-none);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-width: 0;
}

._linkDialogInputWrapper_1qeio_499 > button {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding-right: var(--spacing-2);
  background-color: #0000;
  border: 0;
}

._linkDialogInput_1qeio_493, ._dialogInput_1qeio_519 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
  background-color: #0000;
  border: 0;
}

._linkDialogInput_1qeio_493::placeholder, ._dialogInput_1qeio_519::placeholder {
  color: var(--baseBorder);
}

._linkDialogAnchor_1qeio_529 {
  background-color: highlight;
  z-index: -1;
  position: fixed;
}

._linkDialogAnchor_1qeio_529[data-visible="true"] {
  visibility: visible;
}

._linkDialogAnchor_1qeio_529[data-visible="false"] {
  visibility: hidden;
}

._linkDialogPopoverContent_1qeio_543, ._tableColumnEditorPopoverContent_1qeio_543, ._dialogContent_1qeio_543 {
  filter: drop-shadow(0 2px 2px #0003);
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  border: 1px solid var(--baseBg);
  background-color: var(--basePageBg);
  padding: var(--spacing-1) var(--spacing-2);
  font-size: var(--text-sm);
  display: flex;
}

._largeDialogContent_1qeio_555 {
  filter: drop-shadow(0 2px 2px #0003);
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBgSubtle);
  padding: var(--spacing-4);
  font-size: var(--text-sm);
}

._dialogTitle_1qeio_565 {
  font-size: var(--text-base);
  padding-left: var(--spacing-2);
  font-weight: 600;
}

._dialogCloseButton_1qeio_571 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  background-color: #0000;
  border: 0;
  position: absolute;
  top: 10px;
  right: 10px;
}

._popoverContent_1qeio_578 {
  filter: drop-shadow(0 2px 2px #0003);
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  background-color: var(--baseBgSubtle);
  padding: var(--spacing-2) var(--spacing-2);
  font-size: var(--text-sm);
  z-index: 1;
  display: flex;
}

._popoverArrow_1qeio_590 {
  fill: var(--basePageBg);
}

._linkDialogPreviewAnchor_1qeio_594 {
  margin-right: var(--spacing-3);
  color: var(--accentText);
  border: 1px solid #0000;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

._linkDialogPreviewAnchor_1qeio_594 svg {
  width: var(--spacing-5);
  height: var(--spacing-5);
  margin-left: var(--spacing-2);
}

._linkDialogPreviewAnchor_1qeio_594 span {
  max-width: 14rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

._tooltipTrigger_1qeio_615 {
  align-self: center;
}

._tooltipContent_1qeio_619 {
  z-index: 2;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1) var(--spacing-2);
  font-size: var(--text-xs);
  background-color: var(--baseText);
  color: var(--baseBase);
  position: relative;
}

._tooltipContent_1qeio_619 svg {
  fill: var(--baseText);
}

._actionButton_1qeio_633 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  padding: var(--spacing-1) var(--spacing-1);
  border-radius: var(--radius-medium);
  background-color: #0000;
  border: 0;
}

._actionButton_1qeio_633 svg {
  display: block;
}

._actionButton_1qeio_633:hover {
  background-color: var(--baseBgHover);
}

._actionButton_1qeio_633:active svg {
  transform: translate(1px, 1px);
}

._actionButton_1qeio_633[data-state="on"], ._actionButton_1qeio_633:active {
  background-color: var(--baseBgHover);
  color: var(--baseTextContrast);
}

._primaryActionButton_1qeio_639 {
  background-color: var(--accentSolid);
  color: var(--baseBase);
}

._primaryActionButton_1qeio_639:hover {
  background-color: var(--accentSolidHover);
  color: var(--baseBase);
}

._tableEditor_1qeio_648 {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

._tableEditor_1qeio_648 thead > tr > th {
  text-align: right;
}

._tableEditor_1qeio_648 > tbody > tr > td:not(._toolCell_1qeio_659) {
  border: 1px solid var(--baseBgActive);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: normal;
}

._tableEditor_1qeio_648 > tbody > tr > td:not(._toolCell_1qeio_659) > div {
  outline: none;
}

._tableEditor_1qeio_648 > tbody > tr > td:not(._toolCell_1qeio_659) > div > p {
  margin: 0;
}

._tableEditor_1qeio_648 > tbody > tr > td[data-active="true"]:not(._toolCell_1qeio_659) {
  outline: solid 1px var(--baseSolid);
}

._tableEditor_1qeio_648 ._tableColumnEditorTrigger_1qeio_676, ._tableEditor_1qeio_648 ._tableRowEditorTrigger_1qeio_676, ._tableEditor_1qeio_648 ._addRowButton_1qeio_676, ._tableEditor_1qeio_648 ._addColumnButton_1qeio_676, ._tableEditor_1qeio_648 ._iconButton_1qeio_408 {
  opacity: .15;
}

._tableEditor_1qeio_648:hover ._tableColumnEditorTrigger_1qeio_676, ._tableEditor_1qeio_648:hover ._tableRowEditorTrigger_1qeio_676, ._tableEditor_1qeio_648:hover ._addRowButton_1qeio_676, ._tableEditor_1qeio_648:hover ._addColumnButton_1qeio_676, ._tableEditor_1qeio_648:hover ._iconButton_1qeio_408 {
  opacity: .3;
}

._tableEditor_1qeio_648:hover ._tableColumnEditorTrigger_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._tableRowEditorTrigger_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._addRowButton_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._addColumnButton_1qeio_676:hover, ._tableEditor_1qeio_648:hover ._iconButton_1qeio_408:hover {
  opacity: 1;
}

._toolCell_1qeio_659 {
  text-align: right;
}

._toolCell_1qeio_659 button {
  margin: auto;
  display: block;
}

._tableColumnEditorTrigger_1qeio_676 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  padding: var(--spacing-1);
  border-radius: var(--radius-full);
  opacity: .2;
  background-color: #0000;
  border: 0;
}

._tableColumnEditorTrigger_1qeio_676 svg {
  display: block;
}

._tableColumnEditorTrigger_1qeio_676:hover {
  background-color: var(--baseBgHover);
}

._tableColumnEditorTrigger_1qeio_676:active svg {
  transform: translate(1px, 1px);
}

._tableColumnEditorTrigger_1qeio_676[data-state="on"], ._tableColumnEditorTrigger_1qeio_676:active {
  background-color: var(--baseBgHover);
  color: var(--baseTextContrast);
}

._tableColumnEditorTrigger_1qeio_676[data-active="true"] {
  opacity: 1 !important;
}

._tableColumnEditorToolbar_1qeio_710 {
  display: flex;
}

._tableColumnEditorToolbar_1qeio_710 > button {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  background-color: #0000;
  border: 0;
}

._tableColumnEditorToolbar_1qeio_710 > button svg {
  display: block;
}

._tableColumnEditorToolbar_1qeio_710 > button:hover {
  background-color: var(--baseBgHover);
}

._tableColumnEditorToolbar_1qeio_710 > button:active svg {
  transform: translate(1px, 1px);
}

._tableColumnEditorToolbar_1qeio_710 > button[data-state="on"], ._tableColumnEditorToolbar_1qeio_710 > button:active {
  background-color: var(--baseBgHover);
  color: var(--baseTextContrast);
}

._tableColumnEditorToolbar_1qeio_710 [role="separator"] {
  margin-left: var(--spacing-1);
  margin-right: var(--spacing-1);
}

._toggleGroupRoot_1qeio_723 {
  display: inline-flex;
}

._toggleGroupRoot_1qeio_723 button {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  background-color: #0000;
  border: 0;
}

._toggleGroupRoot_1qeio_723 button svg {
  display: block;
}

._toggleGroupRoot_1qeio_723 button:hover {
  background-color: var(--baseBgHover);
}

._toggleGroupRoot_1qeio_723 button:active svg {
  transform: translate(1px, 1px);
}

._toggleGroupRoot_1qeio_723 button[data-state="on"], ._toggleGroupRoot_1qeio_723 button:active {
  background-color: var(--baseBgHover);
  color: var(--baseTextContrast);
}

._toggleGroupRoot_1qeio_723 button:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}

._toggleGroupRoot_1qeio_723 button:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._tableToolsColumn_1qeio_740 {
  width: 3rem;
}

._tableToolsColumn_1qeio_740 button {
  margin: auto;
  display: block;
}

._leftAlignedCell_1qeio_748 {
  text-align: left;
}

._rightAlignedCell_1qeio_752 {
  text-align: right;
}

._centeredCell_1qeio_756 {
  text-align: center;
}

._addColumnButton_1qeio_676, ._addRowButton_1qeio_676 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1_5);
  background-color: #0000;
  background-color: var(--baseBase);
  border: 0;
  align-items: center;
  display: flex;
}

._addColumnButton_1qeio_676 svg, ._addRowButton_1qeio_676 svg {
  display: block;
}

._addColumnButton_1qeio_676:hover, ._addRowButton_1qeio_676:hover {
  background-color: var(--baseBgHover);
}

._addColumnButton_1qeio_676:active svg, ._addRowButton_1qeio_676:active svg {
  transform: translate(1px, 1px);
}

._addColumnButton_1qeio_676[data-state="on"], ._addRowButton_1qeio_676[data-state="on"], ._addColumnButton_1qeio_676:active, ._addRowButton_1qeio_676:active {
  background-color: var(--baseBgHover);
  color: var(--baseTextContrast);
}

._addColumnButton_1qeio_676 svg, ._addRowButton_1qeio_676 svg {
  margin: auto;
}

._addRowButton_1qeio_676 {
  width: 100%;
  margin-top: var(--spacing-px);
  box-sizing: border-box;
  border-bottom-right-radius: var(--radius-medium);
  border-bottom-left-radius: var(--radius-medium);
}

._addColumnButton_1qeio_676 {
  margin-left: var(--spacing-px);
  height: 100%;
  border-top-right-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}

._dialogOverlay_1qeio_787 {
  background-color: var(--baseBase);
  z-index: 51;
  opacity: .5;
  animation: .15s cubic-bezier(.16, 1, .3, 1) _overlayShow_1qeio_1;
  position: fixed;
  inset: 0;
}

._dialogContent_1qeio_543, ._largeDialogContent_1qeio_555 {
  z-index: 52;
  animation: .15s cubic-bezier(.16, 1, .3, 1) _contentShow_1qeio_1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._dialogContent_1qeio_543:focus, ._largeDialogContent_1qeio_555:focus {
  outline: none;
}

@keyframes _overlayShow_1qeio_1 {
  from {
    opacity: 0;
  }

  to {
    opacity: .5;
  }
}

@keyframes _contentShow_1qeio_1 {
  from {
    opacity: 0;
    transform: translate(-50%, -48%)scale(.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1);
  }
}

._focusedImage_1qeio_830 {
  outline: highlight solid 2px;
}

._imageWrapper_1qeio_834 {
  display: inline-block;
  position: relative;
}

._imageWrapper_1qeio_834[draggable="true"] {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

._editImageButton_1qeio_846 {
  right: var(--spacing-2);
  top: var(--spacing-2);
  background: var(--baseBase);
  border-radius: var(--radius-full);
  position: absolute;
}

._editImageButton_1qeio_846 svg {
  display: block;
}

._inlineEditor_1qeio_858 {
  border-radius: var(--radius-medium);
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  background: var(--baseBg);
  align-items: center;
  display: inline-flex;
}

._blockEditor_1qeio_867 {
  border-radius: var(--radius-medium);
  padding: var(--spacing-2);
  justify-content: stretch;
  gap: var(--spacing-2);
  background: var(--baseBg);
  align-items: center;
  display: flex;
}

._blockEditor_1qeio_867 ._nestedEditor_1qeio_876 {
  flex-grow: 1;
}

._nestedEditor_1qeio_876 {
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-medium);
  background: #fff;
}

._nestedEditor_1qeio_876 > p {
  margin: 0;
}

._nestedEditor_1qeio_876:focus {
  outline: none;
}

._genericComponentName_1qeio_893 {
  font-size: var(--text-sm);
  color: var(--baseText);
  padding-right: var(--spacing-2);
}

._diffSourceToggle_1qeio_899 {
  border-radius: var(--radius-medium);
  display: flex;
}

._diffSourceToggle_1qeio_899 ._toolbarToggleItem_1qeio_186 {
  padding: 0;
}

._diffSourceToggle_1qeio_899 ._toolbarToggleItem_1qeio_186 > span {
  padding: var(--spacing-1) var(--spacing-2);
  display: block;
}

._selectWithLabel_1qeio_912 {
  align-items: center;
  gap: var(--spacing-2);
  margin-left: var(--spacing-2);
  display: flex;
}

._selectWithLabel_1qeio_912 > label {
  font-size: var(--text-sm);
}

._selectWithLabel_1qeio_912 ._selectTrigger_1qeio_278 {
  border: 1px solid var(--baseBorder);
}

._toolbarTitleMode_1qeio_926 {
  font-size: var(--text-sm);
  margin-left: var(--spacing-2);
}

._imageControlWrapperResizing_1qeio_932 {
  touch-action: none;
}

._imageResizer_1qeio_936 {
  width: 7px;
  height: 7px;
  background-color: var(--accentText);
  border: 1px solid var(--baseBg);
  display: block;
  position: absolute;
}

._imageResizer_1qeio_936._imageResizerN_1qeio_945 {
  cursor: n-resize;
  top: -6px;
  left: 48%;
}

._imageResizer_1qeio_936._imageResizerNe_1qeio_951 {
  cursor: ne-resize;
  top: -6px;
  right: -6px;
}

._imageResizer_1qeio_936._imageResizerE_1qeio_957 {
  cursor: e-resize;
  bottom: 48%;
  right: -6px;
}

._imageResizer_1qeio_936._imageResizerSe_1qeio_963 {
  cursor: nwse-resize;
  bottom: -2px;
  right: -6px;
}

._imageResizer_1qeio_936._imageResizerS_1qeio_963 {
  cursor: s-resize;
  bottom: -2px;
  left: 48%;
}

._imageResizer_1qeio_936._imageResizerSw_1qeio_975 {
  cursor: sw-resize;
  bottom: -2px;
  left: -6px;
}

._imageResizer_1qeio_936._imageResizerW_1qeio_981 {
  cursor: w-resize;
  bottom: 48%;
  left: -6px;
}

._imageResizer_1qeio_936._imageResizerNw_1qeio_987 {
  cursor: nw-resize;
  top: -6px;
  left: -6px;
}

._placeholder_1qeio_993 {
  color: var(--baseSolid);
  padding: var(--spacing-3);
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  pointer-events: none;
  display: inline-block;
  position: absolute;
  top: 0;
  overflow: hidden;
}

._rootContentEditableWrapper_1qeio_1006 {
  position: relative;
}

._downshiftContainer_1qeio_1012 {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

._downshiftInputWrapper_1qeio_1018 {
  background-color: var(--baseBase);
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  align-items: center;
  display: flex;
}

._downshiftInputWrapper_1qeio_1018[data-visible-dropdown="true"] {
  border-bottom-left-radius: var(--radius-none);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-width: 0;
}

._downshiftInputWrapper_1qeio_1018 > button {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding-right: var(--spacing-2);
  background-color: #0000;
  border: 0;
}

._downshiftInput_1qeio_1018 {
  font-size: inherit;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
  background-color: #0000;
  border: 0;
}

._downshiftInput_1qeio_1018::placeholder {
  color: var(--baseBorder);
}

._downshiftAutocompleteContainer_1qeio_1048 {
  position: relative;
}

._downshiftAutocompleteContainer_1qeio_1048 ul {
  all: unset;
  box-sizing: border-box;
  font-size: var(--text-sm);
  width: 100%;
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  max-height: var(--spacing-48);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  border-top-width: 0;
  display: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

._downshiftAutocompleteContainer_1qeio_1048 ul[data-visible="true"] {
  display: block;
}

._downshiftAutocompleteContainer_1qeio_1048 ul li {
  padding: var(--spacing-2) var(--spacing-3);
  white-space: nowrap;
  margin-bottom: var(--spacing-1);
  text-overflow: ellipsis;
  overflow-x: hidden;
}

._downshiftAutocompleteContainer_1qeio_1048 ul li[data-selected="true"] {
  background-color: var(--baseBgSubtle);
}

._downshiftAutocompleteContainer_1qeio_1048 ul li[data-highlighted="true"] {
  background-color: var(--baseBgHover);
}

._downshiftAutocompleteContainer_1qeio_1048 ul li:last-of-type {
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}

._textInput_1qeio_1093 {
  all: unset;
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  padding: var(--spacing-2) var(--spacing-3);
}

form._multiFieldForm_1qeio_1101 {
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  flex-direction: column;
  display: flex;
}

form._multiFieldForm_1qeio_1101 ._formField_1qeio_1107 {
  gap: var(--spacing-2);
  flex-direction: column;
  display: flex;
}

form._multiFieldForm_1qeio_1101 ._formField_1qeio_1107 label {
  font-size: var(--text-xs);
}

._markdownParseError_1qeio_1118 {
  border-radius: var(--radius-base);
  border: 1px solid var(--error-color);
  padding: var(--spacing-2);
  margin-block: var(--spacing-2);
  color: var(--error-color);
  font-size: var(--text-xs);
}

:root, ._light-theme_7hn0e_1 {
  --blue1: #fbfdff;
  --blue2: #f5faff;
  --blue3: #edf6ff;
  --blue4: #e1f0ff;
  --blue5: #cee7fe;
  --blue6: #b7d9f8;
  --blue7: #96c7f2;
  --blue8: #5eb0ef;
  --blue9: #0090ff;
  --blue10: #0081f1;
  --blue11: #006adc;
  --blue12: #00254d;
}

._dark-theme_7hn0e_1 {
  --blue1: #0f1720;
  --blue2: #0f1b2d;
  --blue3: #10243e;
  --blue4: #102a4c;
  --blue5: #0f3058;
  --blue6: #0d3868;
  --blue7: #0a4481;
  --blue8: #0954a5;
  --blue9: #0090ff;
  --blue10: #369eff;
  --blue11: #52a9ff;
  --blue12: #eaf6ff;
}

:root, ._light-theme_7hn0e_1 {
  --slate1: #fbfcfd;
  --slate2: #f8f9fa;
  --slate3: #f1f3f5;
  --slate4: #eceef0;
  --slate5: #e6e8eb;
  --slate6: #dfe3e6;
  --slate7: #d7dbdf;
  --slate8: #c1c8cd;
  --slate9: #889096;
  --slate10: #7e868c;
  --slate11: #687076;
  --slate12: #11181c;
}

._dark-theme_7hn0e_1 {
  --slate1: #151718;
  --slate2: #1a1d1e;
  --slate3: #202425;
  --slate4: #26292b;
  --slate5: #2b2f31;
  --slate6: #313538;
  --slate7: #3a3f42;
  --slate8: #4c5155;
  --slate9: #697177;
  --slate10: #787f85;
  --slate11: #9ba1a6;
  --slate12: #ecedee;
}

:root, ._light-theme_7hn0e_1 {
  --grass1: #fbfefb;
  --grass2: #f3fcf3;
  --grass3: #ebf9eb;
  --grass4: #dff3df;
  --grass5: #ceebcf;
  --grass6: #b7dfba;
  --grass7: #97cf9c;
  --grass8: #65ba75;
  --grass9: #46a758;
  --grass10: #3d9a50;
  --grass11: #297c3b;
  --grass12: #1b311e;
  --cyan1: #fafdfe;
  --cyan2: #f2fcfd;
  --cyan3: #e7f9fb;
  --cyan4: #d8f3f6;
  --cyan5: #c4eaef;
  --cyan6: #aadee6;
  --cyan7: #84cdda;
  --cyan8: #3db9cf;
  --cyan9: #05a2c2;
  --cyan10: #0894b3;
  --cyan11: #0c7792;
  --cyan12: #04313c;
  --amber1: #fefdfb;
  --amber2: #fff9ed;
  --amber3: #fff4d5;
  --amber4: #ffecbc;
  --amber5: #ffe3a2;
  --amber6: #ffd386;
  --amber7: #f3ba63;
  --amber8: #ee9d2b;
  --amber9: #ffb224;
  --amber10: #ffa01c;
  --amber11: #ad5700;
  --amber12: #4e2009;
  --red1: #fffcfc;
  --red2: #fff8f8;
  --red3: #ffefef;
  --red4: #ffe5e5;
  --red5: #fdd8d8;
  --red6: #f9c6c6;
  --red7: #f3aeaf;
  --red8: #eb9091;
  --red9: #e5484d;
  --red10: #dc3d43;
  --red11: #cd2b31;
  --red12: #381316;
}

._bold_7hn0e_10 {
  font-weight: bold;
}

._italic_7hn0e_14 {
  font-style: italic;
}

._underline_7hn0e_18 {
  text-decoration: underline;
}

._bold_7hn0e_10 {
  font-weight: 700;
}

._italic_7hn0e_14 {
  font-style: italic;
}

._underline_7hn0e_18 {
  text-decoration: underline;
}

._strikethrough_7hn0e_34 {
  text-decoration: line-through;
}

._underlineStrikethrough_7hn0e_38 {
  text-decoration: underline line-through;
}

._subscript_7hn0e_42 {
  font-size: .8em;
  vertical-align: sub !important;
}

._superscript_7hn0e_47 {
  vertical-align: super;
  font-size: .8em;
}

._code_7hn0e_52 {
  background-color: var(--baseBg);
  font-family: var(--font-mono);
  padding: 1px .25rem;
  font-size: 94%;
}

._nestedListItem_7hn0e_59 {
  list-style: none;
}

._nestedListItem_7hn0e_59:before, ._nestedListItem_7hn0e_59:after {
  display: none;
}

._listitem_7hn0e_69 {
  margin: var(--spacing-2) 0;
}

._listItemChecked_7hn0e_73, ._listItemUnchecked_7hn0e_74 {
  margin-left: 0;
  margin-right: 0;
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
  outline: none;
  margin-inline-start: -1rem;
  list-style-type: none;
  position: relative;
}

._listItemChecked_7hn0e_73 {
  text-decoration: line-through;
}

._listItemUnchecked_7hn0e_74:before, ._listItemChecked_7hn0e_73:before {
  content: "";
  width: var(--spacing-4);
  height: var(--spacing-4);
  cursor: pointer;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

._listItemUnchecked_7hn0e_74[dir="rtl"]:before, ._listItemChecked_7hn0e_73[dir="rtl"]:before {
  left: auto;
  right: 0;
}

._listItemUnchecked_7hn0e_74:focus:before, ._listItemChecked_7hn0e_73:focus:before {
  box-shadow: 0 0 0 2px var(--accentBgActive);
  border-radius: var(--radius-small);
}

._listItemUnchecked_7hn0e_74:before {
  border: 1px solid var(--baseBorder);
  border-radius: var(--radius-small);
}

._listItemChecked_7hn0e_73:before {
  border: 1px solid var(--accentBorder);
  border-radius: var(--radius-small);
  background-color: var(--accentSolid);
  background-repeat: no-repeat;
}

._listItemChecked_7hn0e_73:after {
  content: "";
  cursor: pointer;
  border-color: var(--baseBase);
  top: var(--spacing-0_5);
  width: var(--spacing-1);
  left: var(--spacing-1_5);
  right: var(--spacing-1_5);
  height: var(--spacing-2);
  border-style: solid;
  border-width: 0 var(--spacing-0_5) var(--spacing-0_5) 0;
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

._nestedListItem_7hn0e_59 {
  list-style-type: none;
}

._nestedListItem_7hn0e_59:before, ._nestedListItem_7hn0e_59:after {
  display: none;
}

._admonitionDanger_7hn0e_151, ._admonitionInfo_7hn0e_151, ._admonitionNote_7hn0e_151, ._admonitionTip_7hn0e_151, ._admonitionCaution_7hn0e_151 {
  padding: var(--spacing-2);
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  border-left: 3px solid var(--admonitionBorder);
  background-color: var(--admonitionBg);
}

._admonitionInfo_7hn0e_151 {
  --admonitionBorder: var(--admonitionInfoBorder);
  --admonitionBg: var(--admonitionInfoBg);
}

._admonitionTip_7hn0e_151 {
  --admonitionBorder: var(--admonitionTipBorder);
  --admonitionBg: var(--admonitionTipBg);
}

._admonitionCaution_7hn0e_151 {
  --admonitionBorder: var(--admonitionCautionBorder);
  --admonitionBg: var(--admonitionCautionBg);
}

._admonitionDanger_7hn0e_151 {
  --admonitionBorder: var(--admonitionDangerBorder);
  --admonitionBg: var(--admonitionDangerBg);
}

._admonitionNote_7hn0e_151 {
  --admonitionBorder: var(--admonitionNoteBorder);
  --admonitionBg: var(--admonitionNoteBg);
}

/*# sourceMappingURL=index.b283b539.css.map */
